a {
	color: $color-links;
	transition: $global-transition;

	&:hover,
	&:active,
	&:focus {
		color: darken($color-links, 10%);
	}
}
