.c-list-definition {
	dt {
		min-width: 3em;
		margin: 0 $inuit-global-spacing-unit-small
			$inuit-global-spacing-unit-small 0;
		float: left;
		clear: left;

		&::after {
			content: ':';
		}
	}

	dd {
		margin: 0 0 $inuit-global-spacing-unit-small;
	}
}
