// Settings

@import 'node_modules/inuitcss/settings/settings.core';
@import 'settings/settings.global';
@import 'settings/settings.colors';

// Tools

@import 'node_modules/inuitcss/tools/tools.font-size';
@import 'node_modules/inuitcss/tools/tools.clearfix';
@import 'node_modules/sass-mq/mq';

// Generic

@import 'node_modules/inuitcss/generic/generic.box-sizing';
@import 'node_modules/inuitcss/generic/generic.normalize';
@import 'node_modules/inuitcss/generic/generic.reset';
@import 'node_modules/inuitcss/generic/generic.shared';

// Elements

@import 'node_modules/inuitcss/elements/elements.page';
$inuit-static-images: false;
@import 'node_modules/inuitcss/elements/elements.images';
@import 'node_modules/inuitcss/elements/elements.headings';

@import 'elements/elements.page';
@import 'elements/elements.quotes';
@import 'elements/elements.headings';
@import 'elements/elements.links';

// Objects

@import 'node_modules/inuitcss/objects/objects.wrapper';

.o-wrapper--narrow {
  max-width: 620px;
}

@import 'node_modules/inuitcss/objects/objects.box';
@import 'node_modules/inuitcss/objects/objects.layout';
@import 'node_modules/inuitcss/objects/objects.media';
@import 'node_modules/inuitcss/objects/objects.list-bare';
@import 'node_modules/inuitcss/objects/objects.list-inline';

@import 'objects/objects.band';
@import 'objects/objects.exhibit';

// Components

@import 'components/components.buttons';
@import 'components/components.icons';
@import 'components/components.list-definition';
@import 'components/components.call-out';
@import 'components/components.title-link';
@import 'components/components.link-home';
@import 'components/components.lede';

// Utilities

$inuit-fractions: 2 3;
@import 'node_modules/inuitcss/utilities/utilities.widths';

@import 'node_modules/inuitcss/utilities/utilities.spacings';
@import 'node_modules/inuitcss/utilities/utilities.headings';

@import 'utilities/utilities.text-align';
@import 'utilities/utilities.float';
