.c-link-home {
	display: inline-block;
	margin-bottom: $inuit-global-spacing-unit-large;

	&__img {
		transition: $global-transition;
	}

	&:hover &__img {
		fill: darken($color-button-background, 10%);
	}
}
