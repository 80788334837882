.c-button {
	display: inline-block;
	margin: 0;
	padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit;
	border: 0;
	background: $color-button-background;
	color: $color-button;
	font: inherit;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	transition: $global-transition;
	vertical-align: middle;

	&:hover {
		background-color: darken($color-button-background, 10%);
		color: $color-button;
	}
}
