///*------------------------------------*\
//   #ICONS
//\*------------------------------------*/

.c-icon {
  display: inline-block;
  width: 5 * $inuit-global-spacing-unit;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: transparent;
  font-size: 0;
  vertical-align: middle;
  aspect-ratio: 1;

  @include mq($until: portable) {
    width: 3 * $inuit-global-spacing-unit;
  }

  &--medium {
    width: 3 * $inuit-global-spacing-unit;
  }

  &--small {
    width: 2 * $inuit-global-spacing-unit;
  }

  &--nodejs {
    background-image: url('../images/icon-nodejs.svg');
  }

  &--javascript {
    background-image: url('../images/icon-javascript.svg');
  }

  &--react {
    background-image: url('../images/icon-react.svg');
  }

  &--twitter {
    background-image: url('../images/icon-twitter.svg');
  }

  &--linkedin {
    background-image: url('../images/icon-linkedin.svg');
  }

  &--github {
    background-image: url('../images/icon-github.svg');
  }
}
